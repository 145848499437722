<template>
  <div>
    <div class="top_banner">
      <div class="container_wrap">
        <el-row>
          <el-col :span="6">
            <div :class="{'top_tab':true, 'active': isActiveName === '关于我们'}" @click="isActiveName = '关于我们'">关于我们</div>
          </el-col>
          <el-col :span="6">
            <div :class="{'top_tab':true, 'active': isActiveName === '课程讲师'}" @click="isActiveName = '课程讲师'">课程讲师</div>
          </el-col>
          <el-col :span="6">
            <div :class="{'top_tab':true, 'active': isActiveName === '服务案例'}" @click="isActiveName = '服务案例'">服务案例</div>
          </el-col>
          <el-col :span="6">
            <div :class="{'top_tab':true, 'active': isActiveName === '联系我们'}" @click="isActiveName = '联系我们'">联系我们</div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="bottom_content">
      <div class="container_wrap">
        <transition name="el-fade-in-linear">
          <!-- 关于我们 -->
          <div class="well_frame" v-show="isActiveName === '关于我们'">
            <h2>关于我们</h2>
            <p class="clearfix intro">
              <img :src="require('assets/img/pic/aboutus.png')" align="right" />
              <span>未来网络学堂</span><br />未来网络学堂隶属于南京优速网络科技有限公司，是一家专注网络创新技术领域的技术学习服务提供商，致力于为广大用户提供最优质最高效的技术教育服务。"大学之道，在明明德，在亲民，在止于至善"。深耕网络创新技术领域，紧密连接业界最具创新力的社区和企业，力求将最前沿的技术转化为贴近技术从业者工作日常的教育内容，以臻让前沿技术覆盖更广大的用户群体。<br /><br />网络技术是互联网飞速发展的中流砥柱，伴随着SDN、NFV、云计算、NetDevOps领域实践的逐步加深，我们也在不断的拓宽着自身的技术理解。我们从未停止过追问，如何拓展网络教育的维度，如何成就学员的不凡。唯有“创新”，摒弃低效的教育方式，勾勒出网络创新技术阐释的高效解读，将聚沙成塔的每一步留下浓墨重彩的笔触。<br /><br />秉承对网络创新技术的深刻理解，未来网络学堂汇聚热衷于网络技术教育布道的顶级专家和优秀人才。我们在线上线下不断丰富着教育方式，从理论到实战不断优化着知识的表达。以期用更好的用户体验，缩短知识传播者与受众的距离。<br /><br />我们汇集知识、打造平台，用夜以继日的探索提升我们的知识传播效率。从课程到认证、从企业到个人，我们的每一次思索、每一项改变都是在践行”为了不断创新的网络“的承诺。只为让你窥一窗而见万象，与这变幻世界并驾齐驱。因为下一个伟大的技术变革，或许由你开端。
            </p>
          </div>
        </transition>
        <transition name="el-fade-in-linear">
          <!-- 课程讲师 -->
          <div class="well_frame" v-show="isActiveName === '课程讲师'">
            <h2>课程讲师</h2>
            <div class="alert">如果你喜欢技术、热爱分享、渴望激情，欢迎加入未来网络学堂讲师团队！晚间直播、平时答疑，让我们共同帮助更多人！有意者请将简历发送至：<el-link type="primary" href="mailto:zhengpu@fnedu.com">zhengpu@fnedu.com</el-link></div>
            <el-row :gutter="20">
              <el-col :span="6" v-for="item in portrait" :key="item.id">
                <PortraitItem :item="item"></PortraitItem>
              </el-col>
            </el-row>
            <!-- 分页 -->
            <Pagination :pager="pager" @getPager="getPager" :pageSizes="pager.pageSizes" ref="pagination"></Pagination>
          </div>
        </transition>
        <transition name="el-fade-in-linear">
          <!-- 服务案例 -->
          <div class="well_frame" v-show="isActiveName === '服务案例'">
            <h2>服务案例</h2>
            <div class="alert">未来网络学堂自创建以来，已服务来自超过100所高校、200家企业的2000多名致力于网络创新领域的学员。学堂期待您的加入。</div>
            <h3 class="blue"><span></span>企业</h3>
            <el-row :gutter="20">
              <el-col :span="5" v-for="item in enterprise" :key="item.id">
                <img :src="item.img" />
              </el-col>
            </el-row>
            <h3 class="blue"><span></span>院校</h3>
            <el-row :gutter="20">
              <el-col :span="5" v-for="item in school" :key="item.id">
                <img :src="item.img" />
              </el-col>
            </el-row>
          </div>
        </transition>
        <transition name="el-fade-in-linear">
          <!-- 联系我们 -->
          <div class="well_frame" v-if="isActiveName === '联系我们'">
            <h2>联系我们</h2>
            <el-row :gutter="20">
              <el-col :span="8">
                <h3>南京优速网络科技有限公司</h3>
                <div class="contact">
                  <p><span>业务咨询：</span><span>13245276252</span></p>
                  <p><span>商务合作：</span><span>16651625837</span></p>
                  <p><span>邮　　箱：</span><span>zhaolijuan@fnedu.com<br />liujian@fnedu.com</span></p>
                  <p><span>工作时间：</span><span>周一至周五09:00-18:00</span></p>
                  <p><span>地　　址：</span><span>江苏省南京市江宁区秣周东路7号CENI大厦</span></p>
                </div>
              </el-col>
              <el-col :span="16">
                <BMap></BMap>
              </el-col>
            </el-row>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
  import PortraitItem from 'components/PortraitItem'
  import Pagination from 'components/common/Pagination'
  import BMap from 'views_f/about/BMap';
  import { listTeacher } from "api/course/course/create"
  export default {
    components: {
      PortraitItem,
      Pagination,
      BMap
    },
    data() {
      return {
        isActiveName: '关于我们',
        portrait: [],
        pager: {
          pageSizes: [8, 40, 80],
          pageSize: 8,
          currentPage: 1
        },
        enterprise: [],
        school: []
      }
    },
    created() {
      if(this.$route.query.isActiveName){
        this.isActiveName = this.$route.query.isActiveName
      }
      for(let i=0; i < 15; i++) {
        this.enterprise.push({
          id: i,
          img: require(`assets/img/logo/${ i + 1 }.png`)
        });
      }
      for(let i=15; i < 30; i++) {
        this.school.push({
          id: i,
          img: require(`assets/img/logo/${ i + 1 }.png`)
        });
      }
    },
    mounted() {
      this.getTeacherList();
    },
    methods: {
      getPager(pager) { //获取分页变化后数据
        this.pager = pager;
        this.getTeacherList();
      },
      getTeacherList() {
        const that = this
        let filters = new FormData();
        filters['offset'] = (this.pager.currentPage - 1) * this.pager.pageSize;
        filters['limit'] = this.pager.pageSize;
        listTeacher(filters).then(res => {
          that.portrait = res.data;
          that.pager.total = res.total;
        })
      },
    }
  };
</script>

<style lang="scss" scoped>
  @import "assets/font/font.scss";
  .top_banner {
    height:320px;
    margin-top:-20px;
    margin-left:-20px;
    margin-right:-20px;
    padding-top:20px;
    background:#f6f6f6 url('~@/assets/img/pic/about.png') no-repeat bottom center;
    .container_wrap {
      height: 100%;
      position: relative;
      .el-row {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
      }
      .top_tab {
        padding-top:15px;
        padding-bottom:15px;
        background:rgba(0,0,0,0.5);
        color:#fff;
        font-size:16px;
        text-align:center;
        cursor: pointer;
        position:relative;
        &.active {
          background:#fff;
          color:#009dd9;
          &:after {
            content: '';
            position:absolute;
            top:0;
            left:0;
            right:0;
            height:4px;
            background:#009dd9;
          }
        }
      }
    }
  }
  .bottom_content {
    background:#f6f6f6;
    padding:25px 0;
    margin-left:-20px;
    margin-right:-20px;
    margin-bottom:-20px;
    min-height:500px;
    .well_frame {
      box-shadow:none;
      border:none;
      > h2 {
        font-family: "font-pingfang-bold";
        padding:10px 0;
        margin-top:0;
        border-bottom:2px solid #f0f5f7;
        color:#333;
        margin-bottom:20px;
      }
      .intro {
        line-height:2;
        > span {
          font-family: "font-pingfang-bold";
          font-size:18px;
          color:#009dd9;
          display:inline-block;
          margin-bottom:20px;
        }
      }
      .alert {
        padding:20px;
        background:#f9fbfc;
        border:1px solid #e0eaef;
        margin-bottom:20px;
      }
      h3 {
        font-family: "font-pingfang-bold";
        margin-top:0;
        color:#333;
        margin-bottom:20px;
        > span {
          display:inline-block;
          width:8px;
          height:8px;
          background:#009dd9;
          border-radius:50%;
          margin-right:10px;
          vertical-align: middle;
        }
        &.blue {
          color:#009dd9;
          margin-bottom: 17px;
        }
      }
      .el-col-5 {
        max-width: 20%;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        img {
          display:block;
          margin:0 auto 20px;
        }
      }
      .contact {
        p {
          margin-top:0;
          color:#333;
          display:flex;
          margin-bottom:10px;
          line-height:2;
        }
      }
    }
  }

</style>
